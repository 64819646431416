<template>
  <div class="preview">
   <landdetail :is_preview="1"></landdetail>
  </div>
</template>

<script>
import landdetail from '../collect/landdetail.vue'
export default {
  components:{
    landdetail
  }
}
</script>

<style>

</style>